<template>
  <ValidationObserver v-slot="{ handleSubmit }">
    <form @submit.prevent="handleSubmit(openAlertModal)">
      <div class="form-page">
        <router-link class="back" :to="{ name: '表單項目' }">
          <eva-icon fill="#FFF" name="corner-up-left-outline"></eva-icon>
        </router-link>
        <div class="form-header">每日里程維護</div>

        <div class="form-content">
          <div class="page-wrap">
            <div class="page">
              <div class="form-title">表單資訊</div>
              <div class="form-group">
                <label
                  for="inputDriver"
                  class="col-xs-3 col-sm-2 control-label red"
                  >DC別</label
                >
                <div class="col-xs-9 col-sm-10">
                  <input
                    type="text"
                    class="form-control"
                    v-model="form.StoreCode"
                    readonly
                  />
                </div>
              </div>
              <div class="form-group">
                <label
                  for="inputPlateNumer"
                  class="col-xs-3 col-sm-2 control-label red"
                  >組別</label
                >
                <div class="col-xs-9 col-sm-10">
                  <input
                    type="text"
                    class="form-control"
                    v-model="form.GroupName"
                    readonly
                  />
                </div>
              </div>
              <div class="form-group">
                <label
                  for="inputDate"
                  class="col-xs-3 col-sm-2 control-label red"
                  >車輛保管人</label
                >
                <div class="col-xs-9 col-sm-10">
                  <input
                    type="text"
                    class="form-control"
                    v-model="form.DriverAccount"
                    readonly
                  />
                </div>
              </div>
              <div class="form-group">
                <label
                  for="inputStartMileage"
                  class="col-xs-3 col-sm-2 control-label red"
                  ><span class="text-danger">*</span>車號</label
                >
                <div class="col-xs-9 col-sm-10">
                  <ValidationProvider rules="required" v-slot="{ errors }">
                    <!-- <el-autocomplete
                      v-model="form.LicensePlateNo"
                      :fetch-suggestions="querySearch"
                      placeholder="請選擇或輸入車號"
                      style="width: 100%"
                      @select="changeCarNumber"
                    ></el-autocomplete> -->

                    <multiselect
                      v-model="form.LicensePlateNo"
                      tag-placeholder="新增車號"
                      placeholder="請選擇或輸入新車號"
                      :options="carNumList"
                      :multiple="false"
                      selectLabel="點擊選取"
                      deselectLabel="點擊取消選取"
                      selectedLabel="已選擇"
                      :taggable="true"
                      :reset-after="false"
                      @tag="addTag"
                      @select="changeCarNumber"
                    ></multiselect>

                    <span v-if="errors[0]" class="text-danger ml-1">{{
                      errors[0]
                    }}</span>
                  </ValidationProvider>
                </div>
              </div>
              <div class="form-group">
                <label class="col-xs-3 col-sm-2 control-label red">日期</label>
                <div class="col-xs-9 col-sm-10">
                  <div
                    :class="{ 'red-control': week === '星期日' }"
                    class="form-control date-control"
                    v-if="form.Date !== null"
                  >
                    {{ form.Date }}{{ week }}
                  </div>
                  <div
                    :class="{
                      'red-day-wrap': week === '星期日',
                      'gray-day-wrap': week === '',
                    }"
                    class="day-wrap"
                    v-if="form.Date === null"
                  >
                    <el-date-picker
                      v-model="form.Date"
                      :clearable="false"
                      :picker-options="datePickerOptions"
                      type="date"
                      placeholder="選擇日期"
                      @change="dateChange"
                      :disabled="true"
                    >
                    </el-date-picker>
                    <span>{{ week }}</span>
                  </div>
                </div>
              </div>

              <div class="form-group">
                <label
                  for="inputStartMileage"
                  class="col-xs-3 col-sm-2 control-label red"
                  >起里程</label
                >
                <div class="col-xs-9 col-sm-10">
                  
                  <input
                    type="number"
                    @change="startMileageChangeCheck"
                    v-model.number="form.StartMileage"                    
                    :disabled="tempStartMileage !==0 || form.IsNewRecord === false"
                    class="form-control"
                  />
                  <p style="color: red; font-weight: bold; padding-top: 10px">
                    (車輛里程數請逐日維護)
                  </p>
                </div>
              </div>
              <div class="form-group">
                <label
                  for="inputStopMileage"
                  class="col-xs-3 col-sm-2 control-label red"
                  ><span v-if="!form.noDrive" class="text-danger">*</span>迄里程</label
                >
                <div v-if="!form.noDrive" id="EndMile" class="col-xs-9 col-sm-10">
                  <ValidationProvider
                    :rules="{ required: true,min_value:`${form.StartMileage+1}`}"
                    v-slot="{ errors }"
                  >
                    <input
                      type="number"
                      v-model.number="form.EndMileage"
                      :disabled="form.noDrive === true || !form.LicensePlateNo || form.IsNewRecord === false"
                      class="form-control"
                    />
                    <span v-if="errors[0]" class="text-danger ml-1">{{
                      errors[0]
                    }}</span>
                  </ValidationProvider>
                </div>

                <div v-if="form.noDrive" id="EndMile" class="col-xs-9 col-sm-10">
                  
                    <input
                      type="text"
                      v-model.number="form.EndMileage"
                      :disabled="form.noDrive === true || !form.LicensePlateNo || form.IsNewRecord === false"
                      class="form-control"
                    />
                  
                </div>
                <div style="color: red; font-weight: bold; padding-top: 10px;" v-if="form.IsNewRecord === false" class="col-xs-9 col-sm-10">
                  【該車該日已使用過】
                </div>
              </div>
              <div class="form-group">
                <div class="col-xs-offset-3 col-xs-9 col-sm-offset-2 col-sm-10">
                  <div class="checkbox" id="isMaintained">
                    <label>
                      <input
                      :disabled="form.IsNewRecord === false"
                        type="checkbox"
                        @change="checkDrive"
                        v-model="form.noDrive"
                      />
                      未開（無里程）<span style="color: red; font-weight: bold"
                        >(非上班日請維護店休)</span
                      >
                    </label>
                  </div>
                </div>
              </div>

              <div v-if="form.noDrive" class="form-group">
                <label class="col-xs-3 col-sm-2 control-label red"> <span class="text-danger">*</span> 類別</label>
                <div class="col-xs-9 col-sm-10">
                  <ValidationProvider rules="required" v-slot="{ errors }">
                    <el-select
                    :disabled="form.IsNewRecord === false"
                      style="width: 100%"
                      v-model="form.NoWorkingReason.Category"
                      placeholder="請選擇未開原因"
                    >
                      <el-option
                        v-for="item in reasonOptions"
                        :key="item.TYPE_XXX"
                        :label="item.TYPE_NAM"
                        :value="item.TYPE_XXX"
                      >
                      </el-option>
                    </el-select>
                    <span v-if="errors[0]" class="text-danger ml-1">{{
                      errors[0]
                    }}</span>
                  </ValidationProvider>
                </div>
              </div>

              <div v-if="form.noDrive" class="form-group">
                <label class="col-xs-3 col-sm-2 control-label red">費用</label>
                <div class="col-xs-9 col-sm-10">
                  
                    <input
                    :disabled="form.IsNewRecord === false"
                      v-model="form.NoWorkingReason.Fees"
                      type="text"
                      class="form-control"
                    />
                  
                </div>
              </div>

              <div v-if="form.noDrive" class="form-group">
                <label class="col-xs-3 col-sm-2 control-label red"
                  >內容說明</label
                >
                <div class="col-xs-9 col-sm-10">
                  
                    <textarea
                    :disabled="form.IsNewRecord === false"
                      v-model="form.NoWorkingReason.ContentDescription"
                      cols="30"
                      rows="10"
                      class="form-control"
                    ></textarea>
                  
                </div>
              </div>

              <div class="form-group">
                <label
                  for="inputNextDate"
                  class="col-xs-3 col-sm-2 control-label red"
                  >下次保養日
                  <span
                    v-if="NextDay < fifteenDay"
                    style="color: red; font-weight: bold"
                    >請安排車輛進行保養</span
                  >
                </label>
                <div class="col-xs-9 col-sm-10">
                  <input
                    type="text"
                    v-model="NextDay"
                    :class="{ 'red-control': NextDay < fifteenDay }"
                    class="form-control"
                    disabled
                  />
                </div>
              </div>
              <div class="form-group">
                <label
                  for="inputNextMileage"
                  class="col-xs-3 col-sm-2 control-label red"
                  >下次保養里程
                  <span
                    v-if="NextMileage - form.startMile < 2001"
                    style="color: red; font-weight: bold"
                    >請安排車輛進行保養</span
                  ></label
                >
                <div class="col-xs-9 col-sm-10">
                  <input
                    type="text"
                    v-model="NextMileage"
                    :class="{
                      'red-control': NextMileage - form.startMile < 2001,
                    }"
                    class="form-control"
                    disabled
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <button type="submit" :disabled="form.IsNewRecord === false" class="btn btn-block btn-danger btn-save">
          儲存
        </button>

        <!--  -->
        <div
          class="modal fade"
          id="alertModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
          data-backdrop="static"
        >
          <div class="modal-dialog modal-dialog-scrollable" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <div class="modal-title" id="exampleModalLabel">提醒</div>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <span v-if="form.EndMileage - form.StartMileage > 500"
                  >里程數>500確定儲存嗎?</span
                >
                <span v-if="form.EndMileage - form.StartMileage < 500"
                  >你確定儲存嗎?</span
                >
              </div>

              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-secondary"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  取消
                </button>
                <button
                  type="button"
                  class="btn btn-primary"
                  @click="sendData()"
                >
                  儲存
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </ValidationObserver>
</template>



<script>
/* global $ */
export default {
  data() {
    return {
      datePickerOptions: {
        disabledDate(date) {
          return date > new Date();
        },
      },

      reasonOptions: [],

      carNumList: [],
      weekArr: [
        "星期日",
        "星期一",
        "星期二",
        "星期三",
        "星期四",
        "星期五",
        "星期六",
      ],
      week: "",
      currentPage: 1,
      tempStartMileage:null,
      form: {
        Date: null,
        StartMileage: null,
        EndMileage: null,
        IsWorkDay: null,
        NoWorkingReason: {
          Category: null,
          Fees: 0,
          ContentDescription: null,
        },
        IsNewRecord: false, //用來判斷是put還是post
      },

      NextDay: null, //下次保養日
      NextMileage: null, //下次保養日

      fifteenDay: this.dayjs().add(15, "day").format("YYYY-MM-DD"),
    };
  },
  methods: {
    goComplete() {
      this.$router.push({ name: "每日檢察項目表單完成" });
    },

    checkDrive() {
      if (this.form.noDrive === true) {
        this.form.EndMileage = this.form.StartMileage;
      }
    },

    startMileageChangeCheck(){
      if (this.form.noDrive === true) {
        this.form.EndMileage = this.form.StartMileage;
      }
    },
    dateChange(data) {
      if (!data) {
        return;
      } else {
        const getWeek = new Date(data).getDay();
        this.week = this.weekArr[getWeek];
      }
    },
    openAlertModal() {
      $("#alertModal").modal("show");
    },

    sendData() {
      const vm = this;
      $("#alertModal").modal("hide");

      let httpMethod = "";
      let api = "";

      this.form.LicensePlateNo = this.form.LicensePlateNo.toUpperCase();

      if (vm.form.IsNewRecord === false) {
        httpMethod = "put";

        api = `${process.env.VUE_APP_API}/VehicleMaintenance/Maintenance/UpdateDailyMaintenance`;
      } else {
        httpMethod = "post";

        api = `${process.env.VUE_APP_API}/VehicleMaintenance/Maintenance/AddDailyMaintenance`;
      }

      vm.form.IsWorkDay = !this.form.noDrive;

      if (vm.form.IsWorkDay) {
        vm.form.NoWorkingReason = null;
      }

      console.log("vm.form", vm.form);

      vm.$http[httpMethod](api, vm.form)
        .then((response) => {
          console.log("response", response);
          if (response.data.ErrorMessage === null) {
            vm.$router.push({ name: "每日檢察項目表單完成" });
          } else {
            console.log("response.data", response.data);
            Notification({
              title: "錯誤",
              message: response.data.ErrorMessage,
              type: "error",
              duration: 2000,
            });
          }
        })
        .catch((error) => {
          //vm.$store.dispatch("updateLoading", false);
          console.log("errorerror", error);
          Notification({
            title: "錯誤",
            message: error.response.data,
            type: "error",
            duration: 2000,
          });
        });

      //送出
    },

    //取得車輛未開原因
    getReason() {
      const vm = this;
      const api = `${process.env.VUE_APP_API}/VehicleMaintenance/Maintenance/GetCarTypes`;

      vm.$http
        .get(api)
        .then((response) => {
          vm.reasonOptions = response.data.Data;
        })
        .catch((error) => {
          //vm.$store.dispatch("updateLoading", false);
          Notification({
            title: "錯誤",
            message: error.response.data,
            type: "error",
            duration: 2000,
          });
        });
    },

    //變更車號時需同時變更DC別 並且跑get api撈出之前寫過的資料
    changeCarNumber(selVal) {
      this.user.Driver.DriverVehicles.find((item) => {
        if (item.CARX_NOX === selVal.value) {
          this.NextMileage = item.NEXT_MNTN_MILEAGE;
          this.NextDay = item.NEXT_MNTN_DATE;
        }
      });

      const vm = this;
      const api = `${process.env.VUE_APP_API}/VehicleMaintenance/Maintenance/GetLatestDailyMaintenance/${selVal}`;

      //vm.$store.dispatch("updateLoading", true);
      vm.$http
        .get(api)
        .then((response) => {
          console.log("111", response);
          vm.form = {
            ...vm.form,
            Date: vm.dayjs(response.data.Data.DOCX_DTE).format("YYYY-MM-DD"),
            StartMileage: response.data.Data.STRT_MIL,
            EndMileage:response.data.Data.IS_DRIVING === null ||
              response.data.Data.IS_DRIVING === false ? response.data.Data.STRT_MIL
                : response.data.Data.ENDX_MIL,
            noDrive:
              response.data.Data.IS_DRIVING === null ||
              response.data.Data.IS_DRIVING === false
                ? true
                : false,
            NoWorkingReason: {
              Category: response.data.Data.TYPE_XXX,
              Fees: response.data.Data.FEES_AMT,
              ContentDescription: response.data.Data.MEAT_TXT,
            },
            IsNewRecord: response.data.Data.IS_NEW_RECORD,
          };

          vm.tempStartMileage=response.data.Data.STRT_MIL;

          const getWeek = new Date(vm.form.Date).getDay();
          vm.week = vm.weekArr[getWeek];

        
        })
        .catch((error) => {
          //vm.$store.dispatch("updateLoading", false);
          Notification({
            title: "錯誤",
            message: error.response.data,
            type: "error",
            duration: 2000,
          });
        });
    },


    // querySearch(queryString, cb) {
    //   var restaurants = this.carNumList;
    //   var results = queryString
    //     ? restaurants.filter(this.createFilter(queryString))
    //     : restaurants;

    //   cb(results);
    // },
    // createFilter(queryString) {
    //   return (restaurant) => {
    //     return (
    //       restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) ===
    //       0
    //     );
    //   };
    // },
    addTag(newTag) {
      // const tag = {
      //   value: newTag,
      // }
      this.carNumList.push(newTag);
      this.form.LicensePlateNo = newTag;

      this.changeCarNumber(newTag);
    },
  },
  created() {
    this.getReason();
    // console.log('this.dayjs(this.form.day).format("YYYY/MM/DD")',this.dayjs(this.form.day).format("YYYY/MM/DD"));
    // console.log('this.dayjs.format("YYYY/MM/DD")',this.dayjs().format("YYYY/MM/DD"));

    this.user = JSON.parse(localStorage.getItem("user"));

    this.form.DriverAccount = this.user.Account; //這三個不會動
    this.form.GroupCode = this.user.Driver.GroupId; //這三個不會動
    this.form.GroupName = this.user.Driver.Group; //這三個不會動

    if (this.user.Driver.DriverVehicles.length > 0) {
      this.form.StoreCode = this.user.Driver.DriverVehicles[0].STOR_CDX;
      //this.carNumList = [...this.user.Driver.DriverVehicles];
      // this.carNumList=this.user.Driver.DriverVehicles.map(item=>{
      //   return{
      //     value:item.CARX_NOX
      //   }
      // })

      
      //找出DRIV_MAN等於駕駛的
      let defaultCar = this.user.Driver.DriverVehicles.find(item=>{
        return item.DRIV_MAN ===this.user.Account;
      });      
      this.form.LicensePlateNo =defaultCar.CARX_NOX;
      this.changeCarNumber(this.form.LicensePlateNo);

      this.carNumList = this.user.Driver.DriverVehicles.map((item) => {
        return item.CARX_NOX;
      });
    } else {
      console.log("沒有車牌");
    }
  },
};
</script>